<template>
  <div class="integral list-page">
    <div class="search-box">
      <div class="title">
        <h1>搜索</h1>
        <p>Search Find</p>
      </div>
      <el-form :model="formData" ref="formRef" class="body el-row">
        <el-col :span="8">
          <el-form-item label="工段" prop="workStation">
            <el-select v-model="formData.workStation" placeholder="请选择">
              <el-option
                v-for="item in workStationList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="班组" prop="classGroup">
            <el-select v-model="formData.classGroup" placeholder="请选择">
              <el-option
                v-for="item in classGroupList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="工位" prop="empStation">
            <el-select v-model="formData.empStation" placeholder="请选择">
              <el-option
                v-for="item in empStationList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="班次" prop="classShift">
            <el-select v-model="formData.classShift" placeholder="请选择">
              <el-option
                v-for="item in classShiftList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="16" class="search-btn-box">
          <el-button class="reset" @click="handleReset('formRef')">重置</el-button>
          <el-button class="search active" @click="handleSearch()">搜索</el-button>
        </el-col>
      </el-form>
    </div>
    <div class="list-box">
      <div class="global-operate-block">
        <div class="title">
          <h1>查询结果</h1>
          <p>search result</p>
        </div>
        <div class="global-operate-btn-box">
          <el-button @click="handleExport"><img src="@/assets/imgs/export.png" />导出积分</el-button>
        </div>
      </div>
      <el-table :data="tableData">
        <el-table-column label="序号" type="index" :index="index => index + 1"></el-table-column>
        <el-table-column prop="workStationLabel" label="工段"></el-table-column>
        <el-table-column prop="classGroupLabel" label="班组"></el-table-column>
        <el-table-column prop="empStationLabel" label="工位"></el-table-column>
        <el-table-column prop="classShift" label="班次"></el-table-column>
        <el-table-column prop="points" label="积分"></el-table-column>
        <!-- <el-table-column prop="label" label="累计次数"></el-table-column> -->
        <el-table-column label="操作" class-name="operate-col" width="100"> 
          <template #default="{row}">
            <el-button @click="handleDetail(row)">积分明细</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
          @current-change="handleCurrentPage"
          :current-page="pagination.currentPage"
          :page-size="pagination.pageSize"
          layout="prev, pager, next"
          :total="pagination.total">
        </el-pagination>
      </div>
    </div>

    <!-- 积分明细对话框 -->
    <el-dialog v-model="detailDialogVisible" width="1200px" :show-close="false">
      <template #title>
        <h1>积分明细</h1>
        <p>Integral details</p>
      </template>
      <div class="list-page">
        <div class="search-box">
          <el-form :model="detailFormData" ref="detailFormRef" class="body el-row">
            <el-col :span="6">
              <el-form-item label="开始时间" prop="startTime">
                <el-date-picker v-model="detailFormData.startTime" type="datetime" format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择开始时间"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="结束时间" prop="endTime">
                <el-date-picker v-model="detailFormData.endTime" type="datetime" format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择结束时间"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12" class="search-btn-box">
              <el-button class="reset" @click="handleDetailReset('detailFormRef')">重置</el-button>
              <el-button class="search active" @click="handleDetailSearch()">搜索</el-button>
            </el-col>
          </el-form>
        </div>
        <div class="list-box">
          <div class="global-operate-block">
            <div class="title">
              <h1>查询结果</h1>
              <p>search result</p>
            </div>
            <div class="global-operate-btn-box">
              总计：{{currentCount.points}}分，{{currentCount.times}}次
            </div>
          </div>
          <el-table :data="detailTableData">
            <el-table-column label="序号" type="index" :index="index => index + 1"></el-table-column>
            <el-table-column prop="point" label="积分值"></el-table-column>
            <el-table-column prop="sourceDesc" label="积分来源"></el-table-column>
            <el-table-column prop="createTime" label="时间"></el-table-column>
          </el-table>
          <div class="pagination-box">
            <el-pagination
              @current-change="handleDetailCurrentPage"
              :current-page="detailPagination.currentPage"
              :page-size="detailPagination.pageSize"
              layout="prev, pager, next"
              :total="detailPagination.total">
            </el-pagination>
          </div>
        </div>
      </div>
      <template #footer>
        <el-button @click="closeDetailDialog('detailFormRef')">关 闭</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { sys, common } from '@/api'

export default {
  name: 'Integral',
  data() {
    return {
      empStationList: [], // 工位列表
      classShiftList: [ // 班次列表
        {
          label: 'A',
          value: 'A'
        },
        {
          label: 'B',
          value: 'B'
        }
      ],
      classGroupList: [], // 班组列表
      workStationList: [], // 工段列表

      formData: { // 查询表单
        empStation: '',
        classShift: '',
        classGroup: '',
        workStation: '',
      },
      tableData: [], // 表格数据
      pagination: { // 分页信息
        currentPage: 1,
        pageSize: 10,
        total: 0
      },

      detailDialogVisible: false, // 积分明细对话框visible
      detailFormData: { // 积分明细查询表单
        startTime: '',
        endTime: '',
      },
      detailTableData: [], // 积分明细表格数据
      detailPagination: { // 积分明细分页信息
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      currentOwner: '', // 当前owner
      currentCount: {}, // 当前统计信息
    }
  },
  created() {
    this.getDict()
    this.loadTableData()
  },
  methods: {
    // 获取字典数据
    async getDict() {
      let types = 'EMP_STATION,CLASS_GROUP,WORK_STATION'
      const {code, msg, data = {}} = await common.queryDict({types})
      if (code === 0) {
        this.empStationList = data.EMP_STATION || []
        this.classGroupList = data.CLASS_GROUP || []
        this.workStationList = data.WORK_STATION || []
      } else {
        this.$message.error(msg)
      }
    },
    // 获取列表数据
    async loadTableData() {
      let params = {
        ...this.formData,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize
      }
      const {code, msg, data = [], total = 0} = await sys.getIntegralList(params)
      if (code === 0) {
        this.tableData = data
        this.pagination.total = total
      } else {
        this.$message.error(msg)
      }
    },
    // 改变当前页
    handleCurrentPage(currentPageNo) {
      this.pagination.currentPage = currentPageNo
      this.loadTableData()
    },
    // 搜索
    handleSearch() {
      this.pagination.currentPage = 1
      this.loadTableData()
    },
    // 重置
    handleReset(formName) {
      this.$refs[formName].resetFields()
      this.pagination.currentPage = 1
      this.loadTableData()
    },
    // 积分明细
    handleDetail({empStationValue, classShift}) {
      this.currentOwner = `${empStationValue}@${classShift}`
      this.loadDetailTableData()
      this.searchIntegralDetailCount()
      this.detailDialogVisible = true
    },

    // 查询积分统计信息
    async searchIntegralDetailCount() {
      let params = {
        owner: this.currentOwner,
        ...this.detailFormData,
        pageNum: this.detailPagination.currentPage,
        pageSize: this.detailPagination.pageSize
      }
      const {code, msg, data} = await sys.getIntegralDetailCount(params)
      if (code === 0) {
        this.currentCount = data || {}
      } else {
        this.$message.error(msg)
      }
    },
    // 获取积分明细列表数据
    async loadDetailTableData() {
      let params = {
        owner: this.currentOwner,
        ...this.detailFormData,
        pageNum: this.detailPagination.currentPage,
        pageSize: this.detailPagination.pageSize
      }
      const {code, msg, data = [], total = 0} = await sys.getIntegralDetailList(params)
      if (code === 0) {
        this.detailTableData = data
        this.detailPagination.total = total
      } else {
        this.$message.error(msg)
      }
    },
    // 改变积分明细列表当前页
    handleDetailCurrentPage(currentPageNo) {
      this.detailPagination.currentPage = currentPageNo
      this.loadDetailTableData()
      this.searchIntegralDetailCount()
    },
    // 搜索积分明细列表
    handleDetailSearch() {
      this.detailPagination.currentPage = 1
      this.loadDetailTableData()
      this.searchIntegralDetailCount()
    },
    // 重置积分明细列表
    handleDetailReset(formName) {
      this.$refs[formName].resetFields()
      this.detailPagination.currentPage = 1
      this.loadDetailTableData()
      this.searchIntegralDetailCount()
    },
    // 关闭积分明细对话框
    closeDetailDialog(formName) {
      this.$refs[formName].resetFields()
      this.detailPagination.currentPage = 1
      this.detailDialogVisible = false
    },
    // 导出积分
    async handleExport() {
      await common.downloadFile('/points/record/emp-class/export')
    },
  }
}
</script>

<style>

</style>